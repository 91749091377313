import { InjectionToken } from "tsyringe";

import LocationControllerInterface from "./LocationController/LocationControllerInterface";
import { ReferrerProviderInterface } from "./ReferrerProvider";
import { ShopifyUrlUtils } from "./ShopifyUrlUtils/ShopifyUrlUtils";

export const locationControllerToken = Symbol(
    "locationControllerToken"
) as InjectionToken<LocationControllerInterface>;

export const shopifyUrlUtilsToken = Symbol(
    "shopifyUrlUtils"
) as InjectionToken<ShopifyUrlUtils>;

export const referrerProviderToken = Symbol(
    "referrerProvider"
) as InjectionToken<ReferrerProviderInterface>;
