// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
:host {
    --gw-font-size-base: 16px;

    /* text */
    --gw-font-size-xlg: calc(var(--gw-font-size-base) * 1.5);
    --gw-font-size-xl: calc(var(--gw-font-size-base) * 1.25);
    --gw-font-size-lg: calc(var(--gw-font-size-base) * 1.125);
    --gw-font-size-md: calc(var(--gw-font-size-base) * 1);
    --gw-font-size-sm: calc(var(--gw-font-size-base) * 0.875);
    --gw-font-size-xs: calc(var(--gw-font-size-base) * 0.75);

    /* text weight */
    --gw-font-weight-normal: 400;
    --gw-font-weight-medium: 500;
    --gw-font-weight-semibold: 600;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/text.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;;IAEzB,SAAS;IACT,wDAAwD;IACxD,wDAAwD;IACxD,yDAAyD;IACzD,qDAAqD;IACrD,yDAAyD;IACzD,wDAAwD;;IAExD,gBAAgB;IAChB,4BAA4B;IAC5B,4BAA4B;IAC5B,8BAA8B;AAClC","sourcesContent":[":root,\n:host {\n    --gw-font-size-base: 16px;\n\n    /* text */\n    --gw-font-size-xlg: calc(var(--gw-font-size-base) * 1.5);\n    --gw-font-size-xl: calc(var(--gw-font-size-base) * 1.25);\n    --gw-font-size-lg: calc(var(--gw-font-size-base) * 1.125);\n    --gw-font-size-md: calc(var(--gw-font-size-base) * 1);\n    --gw-font-size-sm: calc(var(--gw-font-size-base) * 0.875);\n    --gw-font-size-xs: calc(var(--gw-font-size-base) * 0.75);\n\n    /* text weight */\n    --gw-font-weight-normal: 400;\n    --gw-font-weight-medium: 500;\n    --gw-font-weight-semibold: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
