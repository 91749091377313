import { PRODUCTS_PAGE_URL } from "@/constants";
import { GP_GW_REFFERER } from "@/constants/get_params";
import { CommonModule } from "@modules/common_module/CommonModule";

class ProductsPageUrlBuilder {
    constructor(private readonly commonModule: CommonModule) {}

    public createUrl(
        productHandle: string,
        variantId?: number,
        referrer?: string
    ) {
        const url = new URL(
            this.commonModule.exports.shopifyUrlUtils.withPrefix(
                `${PRODUCTS_PAGE_URL}/${productHandle}`
            ),
            this.commonModule.exports.locationController.getOrigin()
        );

        const searchParams = new URLSearchParams();

        if (variantId) {
            searchParams.set("variant", variantId.toString());
        }

        if (referrer) {
            searchParams.set(GP_GW_REFFERER, referrer);
        }

        url.search = searchParams.toString();
        return url.toString();
    }
}

export default ProductsPageUrlBuilder;
