import {
    CURRENT_APP_GROWAVE,
    CURRENT_APP_QUERY_PARAMETER,
} from "@/constants/current_app";
import {
    globalLoggerToken,
    gwStorefrontAppInfoToken,
    localizationInfoProviderToken,
} from "@/tokens";
import ApiClient from "@lib/ApiClient";
import ApiClientWithMetrics from "@lib/ApiClient/ApiClientWithMetrics";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import { AnalyticsEventsCollectorModule } from "@modules/analytics_events_collector/AnalyticsEventsCollectorModule";
import { CommonModule } from "@modules/common_module/CommonModule";
import { ProductsServiceInterface } from "@modules/products/services/ProductsServiceInterface";
import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import { container, instanceCachingFactory, singleton } from "tsyringe";

import { ModuleInterface } from "@interfaces/ModuleInterface";

import { GrowaveProductApiService } from "./api_services/GrowaveProductApiService";
import { GrowaveProductLiquidApiService } from "./api_services/GrowaveProductLiquidApiService";
import { ShopifyGraphQLProductsApiService } from "./api_services/ShopifyGraphQLProductsApiService";
import { ProductsQuery } from "./queries/ProductsQuery";
import { ProductsWithQuantityRulesQuery } from "./queries/ProductsWithQuantityRulesQuery";
import { ProductsService } from "./services/ProductsService";
import { ProductsStore } from "./stores/ProductsStore";
import { ProductsWithQuantityRulesStore } from "./stores/ProductsWithQuantityRulesStore";
import {
    commonAuthApiClientToken,
    productApiServiceToken,
    productLiquidApiServiceToken,
    productsApiServiceToken,
    productsQueryToken,
    productsServiceToken,
    productsStoreToken,
    productsWithQuantityRulesQueryToken,
    productsWithQuantityRulesStoreToken,
} from "./tokens";
import ProductsPageUrlBuilder from "./utils/ProductsPageUrlBuilder/ProductsPageUrlBuilder";

interface ProductsModuleExports {
    productsPageUrlBuilder: ProductsPageUrlBuilder;
    productsQuery: ProductsQuery;
    productsService: ProductsServiceInterface;
}

@singleton()
export class ProductsModule implements ModuleInterface<ProductsModuleExports> {
    public exports: ProductsModuleExports;

    public constructor(
        private readonly tokenManagerModule: TokenManagerModule,
        private readonly analyticsEventsCollectorModule: AnalyticsEventsCollectorModule,
        private readonly commonModule: CommonModule
    ) {
        container.register(commonAuthApiClientToken, {
            useFactory: instanceCachingFactory((di) => {
                return new AuthApiClient(
                    new ApiClientWithMetrics(
                        new ApiClient(
                            this.commonModule.exports.baseUrlProvider.appendToBaseUrl(
                                "/storefront-api/common-storefront/v2"
                            ),
                            undefined,
                            {
                                [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                    gwStorefrontAppInfoToken
                                ).currentApp,
                                ...this.commonModule.exports
                                    .designModeQueryParamsProvider.queryParams,
                            },
                            di.resolve(globalLoggerToken)
                        ),
                        this.analyticsEventsCollectorModule.exports.eventsCollector,
                        this.commonModule.exports.locationController,
                        this.analyticsEventsCollectorModule.exports.eventsFactory
                    ),
                    this.tokenManagerModule.exports.tokenManager
                );
            }),
        });
        container.register(productApiServiceToken, {
            useFactory: instanceCachingFactory(() => {
                const gwStorefrontAppInfo = container.resolve(
                    gwStorefrontAppInfoToken
                );
                if (gwStorefrontAppInfo.appName === CURRENT_APP_GROWAVE) {
                    return new GrowaveProductApiService(
                        container.resolve(commonAuthApiClientToken)
                    );
                }
                return container.resolve(productLiquidApiServiceToken);
            }),
        });
        container.register(productLiquidApiServiceToken, {
            useFactory: instanceCachingFactory(() => {
                return new GrowaveProductLiquidApiService(
                    container.resolve(commonAuthApiClientToken)
                );
            }),
        });
        container.register(productsApiServiceToken, {
            useFactory: instanceCachingFactory(() => {
                const localizationInfoProvider = container.resolve(
                    localizationInfoProviderToken
                );
                if (this.commonModule.exports.graphqlApiClient) {
                    return new ShopifyGraphQLProductsApiService(
                        this.commonModule.exports.graphqlApiClient,
                        localizationInfoProvider.getCountryIsoCode(),
                        localizationInfoProvider.getLanguageIsoCode()
                    );
                }
            }),
        });
        container.register(productsStoreToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsStore();
            }),
        });
        container.register(productsWithQuantityRulesStoreToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsWithQuantityRulesStore();
            }),
        });
        container.register(productsQueryToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsQuery(container.resolve(productsStoreToken));
            }),
        });
        container.register(productsWithQuantityRulesQueryToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsWithQuantityRulesQuery(
                    container.resolve(productsWithQuantityRulesStoreToken)
                );
            }),
        });
        container.register(productsServiceToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductsService(
                    container.resolve(productApiServiceToken),
                    container.resolve(productsApiServiceToken),
                    container.resolve(productLiquidApiServiceToken),
                    container.resolve(productsStoreToken),
                    container.resolve(productsWithQuantityRulesStoreToken),
                    container.resolve(globalLoggerToken)
                );
            }),
        });
        container.resolve(productsServiceToken).subscribe();

        this.exports = {
            productsPageUrlBuilder: new ProductsPageUrlBuilder(
                this.commonModule
            ),
            productsQuery: container.resolve(productsQueryToken),
            productsService: container.resolve(productsServiceToken),
        };
    }

    registerProviders() {
        return {};
    }
}
