import {
    globalLoggerToken,
    growaveConfigurationToken,
    gwStorefrontAppInfoToken,
} from "@/tokens";
import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";
import { container, inject, instanceCachingFactory, singleton } from "tsyringe";

import GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";
import { LoggerInterface } from "@interfaces/LoggerInterface";
import { ModuleInterface } from "@interfaces/ModuleInterface";

import { BaseUrlProvider } from "./BaseUrlProvider/BaseUrlProvider";
import { DesignModeProviderInterface } from "./DesignModeProvider/DesignModeProviderInterface";
import { FromShopifyObjectDesignModeProvider } from "./DesignModeProvider/FromShopifyObjectDesignModeProvider";
import DesignModeQueryParamsProvider from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProvider";
import DesignModeQueryParamsProviderInterface from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProviderInterface";
import {
    locationControllerToken,
    referrerProviderToken,
    shopifyUrlUtilsToken,
} from "./di_tokens";
import { GraphQLApiClient } from "./GraphQLApiClient/GraphQLApiClient";
import LocationController from "./LocationController/LocationController";
import LocationControllerInterface from "./LocationController/LocationControllerInterface";
import ReferrerProvider, {
    ReferrerProviderInterface,
} from "./ReferrerProvider";
import { ShopifyUrlUtils } from "./ShopifyUrlUtils/ShopifyUrlUtils";
import { StorefrontApiBaseUrlProvider } from "./StorefrontApiBaseUrlProvider/StorefrontApiBaseUrlProvider";

interface CommonModuleExports {
    locationController: LocationControllerInterface;
    shopifyUrlUtils: ShopifyUrlUtils;
    referrerProvider: ReferrerProviderInterface;
    designModeProvider: DesignModeProviderInterface;
    designModeQueryParamsProvider: DesignModeQueryParamsProviderInterface;
    baseUrlProvider: BaseUrlProvider;
    graphqlApiClient: GraphQLApiClient | null;
}

@singleton()
export class CommonModule implements ModuleInterface<CommonModuleExports> {
    exports: CommonModuleExports;

    public constructor(
        @inject(globalLoggerToken)
        private readonly globalLogger: LoggerInterface,
        @inject(gwStorefrontAppInfoToken)
        private readonly gwStorefrontAppInfo: GwStorefrontAppInfo,
        @inject(growaveConfigurationToken)
        private readonly growaveConfiguration: GrowaveConfigurationEntity
    ) {
        const commonModuleLogger = this.globalLogger.newLogger("CommonModule");

        container.register(locationControllerToken, {
            useFactory: instanceCachingFactory(() => {
                return new LocationController(window.location);
            }),
        });

        container.register(shopifyUrlUtilsToken, {
            useFactory: instanceCachingFactory(() => {
                return new ShopifyUrlUtils(window.Shopify?.routes?.root || "/");
            }),
        });

        container.register(referrerProviderToken, {
            useFactory: instanceCachingFactory(() => {
                return new ReferrerProvider();
            }),
        });

        const designModeProvider = new FromShopifyObjectDesignModeProvider();

        const designModeQueryParamsProvider = new DesignModeQueryParamsProvider(
            designModeProvider,
            container.resolve(locationControllerToken)
        );

        const baseUrlProvider = new BaseUrlProvider(
            this.gwStorefrontAppInfo,
            this.growaveConfiguration
        );

        const storefrontApiBaseUrlProvider = new StorefrontApiBaseUrlProvider(
            this.gwStorefrontAppInfo,
            this.growaveConfiguration
        );

        let graphqlApiClient: GraphQLApiClient | null = null;
        if (growaveConfiguration.storefrontApiAccessToken) {
            graphqlApiClient = new GraphQLApiClient(
                storefrontApiBaseUrlProvider.appendToBaseUrl(
                    "/api/2024-07/graphql.json"
                ),
                growaveConfiguration.storefrontApiAccessToken
            );
        }

        this.exports = {
            locationController: container.resolve(locationControllerToken),
            shopifyUrlUtils: container.resolve(shopifyUrlUtilsToken),
            referrerProvider: container.resolve(referrerProviderToken),
            designModeProvider,
            designModeQueryParamsProvider,
            baseUrlProvider,
            graphqlApiClient,
        };
    }

    public registerProviders() {
        return {};
    }
}
