export enum COMMON_SDK_EVENTS {
    WIDGET_INJECTED = "widgetInjected",
    WIDGET_OPENED = "widgetOpened",
    WIDGET_CLOSED = "widgetClosed",

    AUTHENTICATION_SDK_READY = "authenticationSdkReady",
    REVIEWS_SDK_READY = "reviewsSdkReady",
    REWARDS_SDK_READY = "rewardsSdkReady",
    WISHLISTS_SDK_READY = "wishlistsSdkReady",
}

export enum COMMON_SDK_METHODS {
    SET_GLOBAL_PRODUCT_ID = "setGlobalProductId",
    SET_GLOBAL_VARIANT_ID = "setGlobalVariantId",
}
