import { App } from "@/constants";
import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import ModulesStatusesProvider from "@modules/base_module/ModulesStatusesProvider/ModulesStatusesProvider";

import { SDK_MODULES } from "../constants";
import Sdk from "../Sdk";
import { SdkRegisteredModuleInterface } from "../SdkRegisteredModuleInterface";
import CommonSdkEvents from "./CommonSdkEvents";
import CommonSdkMethods from "./CommonSdkMethods";

class CommonSdk implements SdkRegisteredModuleInterface {
    public readonly events: CommonSdkEvents;

    public readonly methods: CommonSdkMethods;

    public readonly constants = {
        apps: {
            INSTAGRAM: App.Instagram,
            SOCIAL_LOGIN: App.Login,
            QUESTIONS: App.Questions,
            REVIEWS: App.Reviews,
            REWARDS: App.Rewards,
            TIKTOK: App.TikTok,
            WISHLISTS: App.Wishlist,
        },
        modules: {
            AUTHENTICATION: SDK_MODULES.AUTHENTICATION,
            REVIEWS: SDK_MODULES.REVIEWS,
            REWARDS: SDK_MODULES.REWARDS,
            WISHLISTS: SDK_MODULES.WISHLISTS,
        },
    };

    constructor(
        modulesStatusesProvider: ModulesStatusesProvider,
        productIdProvider: ProductIdFromSdkProvider,
        variantIdProvider: ProductVariantIdFromSdkProvider
    ) {
        this.events = new CommonSdkEvents(modulesStatusesProvider);

        this.methods = new CommonSdkMethods(
            productIdProvider,
            variantIdProvider
        );
    }

    public register(sdk: Sdk & { common?: CommonSdk }) {
        sdk.common = this;
    }
}

export default CommonSdk;
